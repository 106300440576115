import ProductGroupQuickAddHtml from './product-group.quick-add.html';
import VendorSearchResultsHtml  from '../vendors/templates/vendors.search.results.html';

export function ProductLiteCtrl (
  $q,
  $rootScope,
  $scope,
  $state,
  $timeout,
  $translate,
  $uibModal,
  confirm,
  messages,
  Product,
  sessionObjAPI,
  streamSearch,
  workshopAPI
) {

  $scope.currency             = sessionObjAPI.currency();
  $scope.form;
  $scope.loaded               = true;
  $scope.originalItemCode     = $scope.product.item_code;
  $scope.session              = sessionObjAPI.base();
  $scope.stockTransfers       = [];
  $scope.wasHeadOfficeChecked = false;

  $scope.Product = new Product($scope.product);

  $scope.qtyAvailable =  $scope.Product.calculateQtyAvailable();

  $scope.vendorSearch = {
    list  : [],
    choose: vendor => {
      $scope.product.vendor_id   = vendor.id;
      $scope.product.vendor_name = vendor.company_name;
    },
    format     : vendor => vendor ? vendor.company_name : '',
    search     : typed  => streamSearch('vendors', typed).then(vendors => $scope.vendorSearch.list.concat(vendors)),
    templateUrl: VendorSearchResultsHtml
  };

  $scope.addGroup = function () {
    $uibModal.open({
      controller : 'ProductGroupQuickAddLiteCtrl',
      templateUrl: ProductGroupQuickAddHtml,
      resolve    : {
        categories: () => $scope.categories
      }
    }).result
    .then(group => {
      $scope.refreshGroups()
      .then(groups => {
        $scope.product_groups             = groups;
        $scope.product_group              = group;
        $scope.product.product_group_name = group.name;
      });
    });
  };

  $scope.checkHeadOffice = function () {
    if ($scope.isItemCodeUntouched() || $scope.wasHeadOfficeChecked) {
      return;
    }

    $scope.Product.findInHeadOffice()
    .then(( product ) => {
      if (product) {
        $scope.product = product;

        $scope.Product = new Product($scope.product);

        if ($scope.product.vendor_id) {
          $scope.getVendor($scope.product.vendor_id);
        }
      }

      $scope.wasHeadOfficeChecked = true;
    })
    .finally(() => {
      $scope.wasHeadOfficeChecked = true;
    });
  };

  $scope.costExcludingTaxUpdated = function () {
    $scope.product.cost_including_tax = $scope.product.gst_free
      ? $scope.product.cost
      : $scope.Product.calculateCostWithTax($scope.product.cost, $scope.category.purchase_tax_rate);
  };

  $scope.costIncludingTaxUpdated = function () {
    $scope.product.cost = $scope.product.gst_free
      ? $scope.product.cost_including_tax
      : $scope.Product.calculateCostWithoutTax($scope.product.cost_including_tax, $scope.category.purchase_tax_rate);
  };

  $scope.delete = function() {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.DELETE_PRODUCT'))
    .then(() => workshopAPI.delete('/product', $scope.product.id))
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.PRODUCT'), 'success');

      $scope.form.$setPristine();

      $state.go('app.products');
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.getCategory = function (id) {
    if (id) {
      workshopAPI.get('/product_category', id)
      .then(category => {
        $scope.product.product_category_name = category.name;

        $scope.category = {
          category_name    : category.name,
          id               : category.id,
          purchase_tax_rate: category.purchase_tax_rate
        };

        if ( $scope.product.cost ) {
          $scope.costExcludingTaxUpdated();
        }
      })
      .catch(err => messages.error(err));
    }
    else {
      $scope.product.product_category_name = '';

      $scope.category = {
        category_name    : '',
        id               : '',
        purchase_tax_rate: $scope.currency.purchases_tax_rate
      };

      if ( $scope.product.cost ) {
        $scope.costExcludingTaxUpdated();
      }
    }
  };

  $scope.getProductGroup = function () {
    if ($scope.product.product_group_name) {
      workshopAPI.get('/product_group', $scope.product.product_group_name)
      .then(group => {
        $scope.product_group = group;

        if ($scope.product_group.length) {
          $scope.getCategory($scope.product_group[0].product_category_id);
        }
      });
    }
    else {
      $scope.getCategory();
    }
  };

  $scope.getVendor = function (id) {
    workshopAPI.get('/vendor', id)
    .then(vendor => {
      $scope.product.vendor_name = vendor.company_name;

      $scope.vendorSearch.selected = {
        company_name: vendor.company_name,
        id          : vendor.id
      };
    })
    .catch(err => messages.error(err));
  };

  $scope.init = function () {
    if ($scope.product.vendor_id) {
      $scope.getVendor($scope.product.vendor_id);
    }

    if ($scope.product.product_group_name) {
      $scope.getProductGroup();
    }

    if ($rootScope.Company.isMultiBranch()) {
      $scope.Product.getStockTransfers()
      .then(transfers => $scope.stockTransfers = transfers);
    }

    $scope.getCategory();
    $scope.initProductTypes();
    $scope.initProductGroups();
  };

  $scope.initProductTypes = function () {
    workshopAPI.get('/system/product_types/', null, 'productTypes')
    .then(types => $scope.product_types = types);
  };

  $scope.initProductGroups = function () {
    workshopAPI.get('/product_groups')
    .then(groups => $scope.product_groups = groups);
  };

  $scope.isItemCodeUntouched = function () {
    return $scope.originalItemCode === $scope.product.item_code;
  };

  $scope.pushDownPricingInfo = function () {
    $q.when()
    .then(() => $scope.form.$dirty ? $scope.Product.save() : $q.when())
    .then(() => $scope.Product.pushDownPricingInfo())
    .then(() => {
      messages.show($scope.form.$dirty ? 'Product updated and pricing information pushed down' : 'Pricing information pushed down', 'success');

      $scope.form.$setPristine();

      $state.reload();
    })
    .catch(err => messages.error(err));
  };

  $scope.refreshGroups = function () {
    return workshopAPI.get('/product_groups');
  };

  $scope.save = function (form) {
    /**
     * WOR-2733
     *
     * certain validations need to be made before a save can happen.
     * The item code needs to be checked for uniqueness and if the
     * user is linked to a head office, then the product needs to be
     * looked up in the head office. A lot of times a user will enter
     * an item code then hit save right away. This doesn't give the
     * blur effect time to complete so we check that as well as the
     * async validator flag. Keep calling save until those validations
     * are resolved.
     */
    if ( !$scope.isItemCodeUntouched() && ( angular.isUndefined(form.item_code.duplicate) || !$scope.wasHeadOfficeChecked ) ) {
      return $timeout(() => $scope.save(form));
    }

    $scope.Product
      .save(form)
      .then(product => {
        $scope.product = product;

        return !form.retail_price.$pristine
          ? workshopAPI.get('/products/product_bom_item_count', $scope.product.id)
          : $q.when(0);
      })
      .then(count => count
        ? confirm.generic($translate.instant('JS_SPACE.MESSAGES.PRODUCT_ATTACHED_TO_BUNDLE'))
          .then(()  => workshopAPI.patch('/bom_items/update_retail_price', $scope.product, 'product'))
          .catch(() => $q.when())
        : $q.when()
      )
      .then(() => {
        messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.PRODUCT'), 'success');

      $scope.form.$setPristine();
      $state.go('app.product', {
        id: $scope.product.id
      }, {
        reload: true
      });
    })
    .catch(err => messages.error(err));
  };

  $scope.syncWithHeadOffice = function () {
    $q.when()
    .then(() => $scope.form.$dirty ? $scope.Product.save() : $q.when())
    .then(() => workshopAPI.patch('/products/sync_multibranch_product_entry', $scope.product))
    .then(() => {
      messages.show($translate.instant('MULTI_BRANCH_SPACE.PRODUCT_LINKED'), 'success');

      $scope.form.$setPristine();

      $state.reload();
    })
    .catch(err => messages.error(err));
  };

  $scope.toggleGSTFree = function () {
    $scope.product.cost_including_tax = $scope.product.gst_free ?
      $scope.product.cost :
      $scope.Product.calculateCostWithTax($scope.product.cost, $scope.product);
  };

  $scope.validateItemCode = function ( item_code ) {
    const productApi = new Product({ item_code });

    return productApi.validateItemCode();
  };

  /**
   * WOR-2733
   *
   * reset flag to check head office when item code has changed
   */
  $scope.$watch('product.item_code', ( newVal, oldVal ) => {
    if ( ( newVal && newVal !== oldVal )
      || ( newVal && oldVal && newVal.toLowerCase() !== oldVal.toLowerCase() )
    ) {
      $scope.wasHeadOfficeChecked = false;

      $scope.checkHeadOffice();
    }
  });

  $scope.init();
};